export const GrayArrowDrop = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5858 7C11.4767 7 11.9229 5.92286 11.2929 5.29289L6.70711 0.707106C6.31658 0.316582 5.68342 0.316583 5.29289 0.707107L0.707106 5.29289C0.0771408 5.92286 0.523309 7 1.41421 7L10.5858 7Z"
        fill="#8D96A5"
      />
    </svg>
  );
};
