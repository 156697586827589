import {
  $ProcedureInstructions,
  FetchAllCategoriesForInstructionsPrograms,
  FetchEmployeeInstructionsCategories,
  SELECTS_ID,
} from "@store/company/procedure-instrunctions-store";
import { $Warning, $WarningStates, FetchWarning } from "@store/warning-store";
import { BodyBold, BodyNormal } from "@ui/fonts";
import {
  DropDownDeadlineSelect,
  SimpleDropDownMultiSelect,
} from "@ui/drop-down";
import { FileLoadSVG, WarnSVG } from "@components/svgs";
import {
  Procedure,
  ProcedureInstructionQueryParams,
  procedureInstructionsSortKeys,
} from "@interfaces/company/procedure";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  useBriefingProcedureGeneralsQuery,
  useBriefingProceduresQuery,
} from "@lib/procedures/useBriefingProceduresQuery";

import { BriefingProcedureParamsProvider } from "../briefing-params-context";
import { Button } from "@ui/button";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import { CommonSearch } from "@ui/common-search";
import { DatePeriod } from "@ui/date-period";
import ProcedureControlService from "@services/procedure-control-service";
import ProcedureService from "@services/programs-service";
import { ProcedureTablesInstructions } from "../procedure-tables-instructions";
import { downloadBlob } from "@utils/downloadBlob";
import style from "@scss/pages/company/procedure-control.module.scss";
import { useDepartmentsGetAllQuery } from "@lib/departments-get-all/departments-get-all";
import { useEmployeesGetAllQuery } from "@lib/employees-get-all/employees-get-all";
import { usePositionsGetAllQuery } from "@lib/positions-get-all/positions-get-all";
import { useSorting } from "@hooks/useSorting";
import { useStore } from "effector-react";
import { СategoriesT } from "@interfaces/directory/version";
import { useCompaniesQuery, useSelectedCompanyQuery } from "@lib/company";
import { getProcedureControlParamsArray } from "@utils/getProcedureControlParamsArray";

type SelectListType = {
  id: number;
  title: string;
};

const LIMIT = 20;
const getOffset = (page: number) => LIMIT * (page - 1);

const getOptionsList = <T,>(
  list: T[],
  getValue: (item: T) => SelectListType["id"],
  getLabel: (item: T) => SelectListType["title"]
): SelectListType[] => {
  return list.map((item) => ({
    id: getValue(item),
    title: getLabel(item),
  }));
};

const getParamsArray = (
  arr: SelectListType[],
  totalItemsCount: number,
  useTitleAsValue: boolean
): (number | string)[] | undefined => {
  if (arr.length === 0) return undefined;
  if (arr.length === totalItemsCount) return undefined;

  if (useTitleAsValue) {
    return arr.map((item) => item.title);
  }

  return arr.map((item) => item.id);
};

interface InstructionsComponentsProps {
  withoutFilters?: boolean;
  withoutEmpty?: boolean;
  ignoredKeys?: (keyof Procedure)[];
  defaultFilters?: Partial<ProcedureInstructionQueryParams>;
  isGeneral?: boolean;
}

export const InstructionsComponents: React.FC<InstructionsComponentsProps> =
  memo(
    ({
      isGeneral = false,
      withoutFilters = false,
      withoutEmpty = false,
      defaultFilters,
      ignoredKeys,
    }) => {
      const { company } = useSelectedCompanyQuery();
      const { companies } = useCompaniesQuery();

      const warning = useStore($Warning);
      const warningState = useStore($WarningStates);
      const TYPE = "briefing";
      const directionInstructions = useStore($ProcedureInstructions);
      const [page, setPage] = useState(1);
      const [onModal, setOnModal] = useState<boolean>(false);
      const [selectedDate, setSelectedDate] = useState<Date | null>(null);
      const [textDate, setTextDate] = useState<string>("");
      const [listIds, setListIds] = useState<string>("");
      const [choiceCategory, setChoiceCategory] = useState<СategoriesT[]>();
      const [instructList, setInstructList] = useState<SelectListType[]>([]);
      const [queryFilter, setQueryFilter] = useState<
        Omit<
          ProcedureInstructionQueryParams,
          "departments" | "positions" | "employees"
        >
      >(defaultFilters ?? {});
      const [selectedDepartments, setSelectedDepartments] = useState<
        SelectListType[]
      >([]);
      const [selectedPositions, setSelectedPositions] = useState<
        SelectListType[]
      >([]);
      const [selectedEmployees, setSelectedEmployees] = useState<
        SelectListType[]
      >([]);
      const [selectedCompanies, setSelectedCompanies] = useState<
        SelectListType[]
      >([]);
      const [selectedPrograms, setSelectedPrograms] = useState<
        SelectListType[]
      >([]);

      const { data: getAllDepartments } = useDepartmentsGetAllQuery(
        {
          companies: selectedCompanies.map((c) => c.id),
        },
        true
      );
      const { data: getAllEmployees } = useEmployeesGetAllQuery(
        {
          companies: selectedCompanies.map((c) => c.id),
          departments: selectedDepartments.map((d) => d.title),
          positions: selectedPositions.map((p) => p.title),
        },
        true
      );
      const { data: getAllPositions } = usePositionsGetAllQuery(
        {
          companies: selectedCompanies.map((c) => c.id),
          departments: selectedDepartments.map((d) => d.title),
        },
        true
      );

      const [search, setSearch] = useState<string>("");
      const [choiceID, setChoiceID] = useState<number | null>(null);

      const companiesList = getOptionsList(
        companies ?? [],
        (c) => c.id,
        (c) => c.name
      );
      const employeesList = getOptionsList(
        getAllEmployees,
        (e) => e.id,
        (e) => e.name ?? String(e.id)
      );
      const departmentsList = getOptionsList(
        getAllDepartments,
        (d) => d.id,
        (d) => d.title ?? String(d.id)
      );
      const positionsList = getOptionsList(
        getAllPositions,
        (p) => p.id,
        (p) => p.title ?? String(p.id)
      );

      const sorting = useSorting(procedureInstructionsSortKeys, "ASC");
      const briefingParams = {
        ...queryFilter,
        categories: choiceID ? [choiceID] : undefined,
        limit: LIMIT,
        offset: getOffset(page),
        searchString: search,
        departments: getProcedureControlParamsArray(
          selectedDepartments,
          departmentsList.length,
          isGeneral
        ),
        positions: getProcedureControlParamsArray(
          selectedPositions,
          positionsList.length,
          isGeneral
        ),
        employees: getProcedureControlParamsArray(
          selectedEmployees,
          employeesList.length,
          isGeneral
        ),
        companies: selectedCompanies.map((c) => c.id),
        programs: selectedPrograms.map((p) => p.id),
        ...sorting.getActiveSorting(),
      };
      const briefingControlsCompany = useBriefingProceduresQuery(
        briefingParams,
        isGeneral
      );

      const briefingControlsIsGeneral = useBriefingProcedureGeneralsQuery(
        briefingParams,
        isGeneral
      );

      const briefingControls =
        briefingControlsCompany ?? briefingControlsIsGeneral;

      const hasNextPage =
        (briefingControls.data?.[0]?.body?.length ?? 0) >= LIMIT;

      const withCompaniesFilter = !ignoredKeys?.includes("companyName");

      const handlerChangeFilter = (key: string, value: any) => {
        if (key === "deadline") {
          if (!value) {
            setQueryFilter((prevFilters) => ({
              ...prevFilters,
              deadline: undefined,
            }));
            return;
          }
          setQueryFilter((prevFilters) => ({
            ...prevFilters,
            deadline: value
              .split(";")
              .map((item: string) => item.split(","))
              .flat(),
          }));
          return;
        }

        if (value === "") {
          return setQueryFilter((prevFilters) => ({
            ...prevFilters,
            [key]: undefined,
          }));
        }

        const filtersToResetPage = [
          "departments",
          "positions",
          "employees",
          "companies",
          "programs",
        ];
        if (filtersToResetPage.includes(key) && page !== 1) {
          setPage(1);
        }

        if (key === "departments") {
          setSelectedPositions([]);
          setSelectedEmployees([]);
          return setSelectedDepartments(value);
        }
        if (key === "positions") {
          setSelectedEmployees([]);
          return setSelectedPositions(value);
        }
        if (key === "employees") {
          return setSelectedEmployees(value);
        }
        if (key === "programs") {
          return setSelectedPrograms(value);
        }
        if (key === "companies") {
          setSelectedDepartments([]);
          setSelectedPositions([]);
          setSelectedEmployees([]);
          return setSelectedCompanies(value);
        }

        setQueryFilter((prevFilters) => ({ ...prevFilters, [key]: value }));
      };

      const closeModalCalendar = () => setOnModal(false);
      const openModalCalendar = () => setOnModal(true);

      const areTablesEmpty = useMemo(() => {
        return false;
      }, []);

      const handlerLerningList = (values?: SelectListType[]) => {
        let idsTrainingProgram = values && values?.map((item) => item.id);

        ProcedureService.Programs(
          "briefing",
          idsTrainingProgram,
          true,
          (err, res) => {
            if (err || !res) {
              throw new Error(
                "При получении списка программ обучения категорий произошла ошибка"
              );
            }
            if (res.data) {
              const programs = res.data.map((item) => item.programs).flat();
              setInstructList(programs);
              if (company) {
                FetchWarning(company.id);
              }
            }
          }
        );
      };

      const dropDownData = useMemo(() => {
        const filters = [
          {
            label: "Подразделение",
            list: departmentsList,
            type: "departments",
            value: selectedDepartments,
          },
          {
            label: "Должность",
            list: positionsList,
            type: "positions",
            value: selectedPositions,
          },
          {
            label: "Вид инструктажа",
            list: instructList,
            type: "programs",
            value: selectedPrograms,
          },
          {
            label: "Сотрудники",
            list: employeesList,
            type: "employees",
            value: selectedEmployees,
          },
        ];

        if (withCompaniesFilter) {
          filters.unshift({
            label: "Компания",
            list: companiesList,
            type: "companies",
            value: selectedCompanies,
          });
        }
        return filters;
      }, [
        withCompaniesFilter,
        companiesList,
        departmentsList,
        employeesList,
        instructList,
        positionsList,
        selectedDepartments,
        selectedPositions,
        selectedEmployees,
        selectedPrograms,
        selectedCompanies,
      ]);

    useEffect(() => {
      if (!defaultFilters?.employees) return;
      const ids = defaultFilters.employees.map((id) => parseInt(String(id)))
      const defaultEmployees = getAllEmployees.filter((item) => ids.includes(item.id))
      setSelectedEmployees(getOptionsList(
        defaultEmployees,
        (p) => p.id,
        (p) => p.name ?? String(p.id)
      ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters?.employees])

      useEffect(() => {
        if (isGeneral || !company) return;

        setSelectedCompanies([{ id: company.id, title: company.name }]);
      }, [isGeneral, company]);

      useEffect(() => {
        const isEmployeePage = selectedEmployees?.length === 1;
        if (isEmployeePage) {
          FetchEmployeeInstructionsCategories(+selectedEmployees![0]);
        } else {
          FetchAllCategoriesForInstructionsPrograms({ isIncludeChanges: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => handlerLerningList(), []);

      const handleFilterAndSearch = () => {
        handlerChange(listIds, queryFilter);
      };

      const handlerChange = (
        listIds: string,
        filter?: ProcedureInstructionQueryParams
      ) => {
        setListIds(listIds);
        setQueryFilter(
          Object.assign({}, defaultFilters, {
            ...filter,
            searchString: search,
          })
        );
      };

      useEffect(() => {
        if (directionInstructions?.categories && listIds) {
          const stateListIDs = listIds
            .split(",")
            .map(Number)
            .filter((id) => id !== SELECTS_ID);
          const newDirectionInstructions =
            directionInstructions?.categories.filter((obj) =>
              stateListIDs.includes(obj.id)
            );

          setChoiceCategory(newDirectionInstructions);
          setChoiceID(newDirectionInstructions[0]?.id ?? null);
        }
        if (directionInstructions?.categories && !listIds) {
          const allDirectionInstructions =
            directionInstructions?.categories?.filter(
              (item) => item.id !== SELECTS_ID
            );

          setChoiceCategory(allDirectionInstructions);
          setChoiceID(allDirectionInstructions[0]?.id ?? null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [directionInstructions?.categories, listIds]);

      useEffect(() => setPage(1), [choiceID, queryFilter]);

      const getExcelFileName = (categoryId: number | null): string => {
        let filename = "";

        if (categoryId !== null) {
          const category = directionInstructions.categories?.find(
            (category) => category.id === choiceID
          );
          if (category) {
            if (isGeneral) {
              filename = `Контроль инструктажей [${category.title}].xlsx`;
            } else {
              filename = `${company?.name} - Контроль инструктажей [${category.title}].xlsx`;
            }
          }

          return filename;
        }

        if (isGeneral) {
          filename = `Контроль инструктажей.xlsx`;
        } else {
          filename = `${company?.name} - Контроль инструктажей.xlsx`;
        }

        return filename;
      };

      const handleDownloadExcelFile = () => {
        const request = isGeneral
          ? ProcedureControlService.GetInstructionsExcelFileForAllCompanies
          : ProcedureControlService.GetInstructionsExcelFile;

        request(
          { ...briefingParams, offset: undefined, limit: undefined },
          (err, res) => {
            if (err || !res) return;

            const filename = getExcelFileName(choiceID);
            downloadBlob(res.data, filename);
          }
        );
      };

      const handleDownloadAllCategories = () => {
        const request = isGeneral
          ? ProcedureControlService.GetInstructionsExcelFileForAllCompanies
          : ProcedureControlService.GetInstructionsExcelFile;

        request(
          {
            ...briefingParams,
            categories: undefined,
            offset: undefined,
            limit: undefined,
          },
          (err, res) => {
            if (err || !res) return;

            const filename = getExcelFileName(null);
            downloadBlob(res.data, filename);
          }
        );
      };

      return (
        <BriefingProcedureParamsProvider value={briefingParams}>
          {!withoutFilters && (
            <div>
              <CommonSearch
                className="ml-auto"
                onChange={handleFilterAndSearch}
                setSearch={setSearch}
              />
            </div>
          )}
          {!warning?.isBriefingFill && !warningState.isLoading && (
            <div className={style.page_warning}>
              <WarnSVG /> Заполните данные по инструктажам сотрудников
            </div>
          )}
          {areTablesEmpty && (
            <div className={style.page_warning}>
              <WarnSVG /> Укажите требуемые виды инструктажей для должности
              сотрудника
            </div>
          )}
          <div>
            {!withoutFilters && directionInstructions && (
              <div className={style.direction_list}>
                <div
                  className={style.direction_list_dowload}
                  tabIndex={0}
                  onClick={handleDownloadAllCategories}
                >
                  <FileLoadSVG />
                  Скачать все таблицы
                </div>
                <div
                  className={style.direction_list_dowload}
                  tabIndex={0}
                  onClick={handleDownloadExcelFile}
                >
                  <FileLoadSVG />
                  Скачать текущую таблицу
                </div>
              </div>
            )}
            <div className={style.wrapper}>
              {!withoutFilters && (
                <div className={style.head_container}>
                  <div className={style.head_filter}>
                    {dropDownData.map(({ label, list, type, value }) => (
                      <SimpleDropDownMultiSelect
                        key={type}
                        label={label}
                        options={list}
                        emptyIsFull
                        value={value}
                        onChange={(values) => handlerChangeFilter(type, values)}
                      />
                    ))}
                    <div
                      onClick={openModalCalendar}
                      className={style.select_date}
                    >
                      <div className={style.select_label}>
                        Дата следующего инструктажа
                      </div>
                      {textDate}
                      <CalendarMonth
                        color="action"
                        className={style.select_icon}
                      />
                    </div>
                    <DatePeriod
                      active={onModal}
                      setTextDate={setTextDate}
                      closeModal={closeModalCalendar}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      type="date"
                      handlerChangeFilter={handlerChangeFilter}
                    />
                    <DropDownDeadlineSelect
                      type="deadline"
                      title="Дней до инструктажа"
                      handlerChangeFilter={handlerChangeFilter}
                    />
                  </div>
                  <Button onClick={handleFilterAndSearch}>
                    <BodyNormal>Фильтровать</BodyNormal>
                  </Button>
                </div>
              )}
              <div className={style.choise_programm}>
                {!!choiceCategory &&
                  choiceCategory.map((item) => (
                    <Button
                      key={item.id}
                      color={choiceID !== item.id ? "white-green" : "green"}
                      borderColor={"green"}
                      onClick={() => setChoiceID(item.id)}
                    >
                      <BodyBold>{item.title}</BodyBold>
                    </Button>
                  ))}
              </div>
              {!!choiceID && (
                <div className={style.table}>
                  <ProcedureTablesInstructions
                    TYPE={TYPE}
                    withoutEmpty={withoutEmpty}
                    ignoredKeys={ignoredKeys}
                    sorting={sorting}
                    tables={briefingControls.data ?? []}
                    isLoading={briefingControls.isLoading}
                    offset={getOffset(page)}
                    page={page}
                    totalPages={hasNextPage ? page + 1 : page}
                    onPageChange={setPage}
                  />
                </div>
              )}
            </div>
          </div>
        </BriefingProcedureParamsProvider>
      );
    }
  );
