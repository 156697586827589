import { $AllTariffs, fetchAllTariffs } from "@store/payment-store";
import {
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";
import { TariffEnum } from "@interfaces/company/company";
import { HeaderPropsT, MenuBurgerIconsPropsT } from "@interfaces/common";
import { Link, useHistory } from "react-router-dom";
import { concatApiUrl, concatClientUrl } from "@utils/api-tools";
import { useEffect, useRef, useState } from "react";

import AuthService from "@services/auth-service";
import { BodyNormal } from "@ui/fonts";
import Loader from "@ui/indicators/loader";
import { UserDataT } from "@interfaces/user";
import clsx from "clsx";
import logo from "@assets/images/header-logo.png";
import moment from "moment";
import { renderDayWithEnding } from "@utils/renderDayEndingDependsOnCount";
import style from "@scss/components/header.module.scss";
import useModal from "@modals/modal-hook";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { useStore } from "effector-react";
import { useQueryClient } from "react-query";
import { useSelectedCompanyQuery } from "@lib/company";
import { CompanySearch } from "@components/CompanySearch";
import { CompanyDiskUsage } from "@components/CompanyDiskUsage";
import { getCompanyLogo } from "@utils/getCompanyLogo";

const MenuBurgerIcons: React.FC<MenuBurgerIconsPropsT> = ({
  menuDisplay,
  onBurgerClick,
}) => {
  if (menuDisplay) {
    return (
      <svg
        className={clsx(style.menu_burg)}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onBurgerClick}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    );
  }

  return (
    <svg
      className={clsx(style.menu_burg)}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onBurgerClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  );
};

const Header: React.FC<HeaderPropsT> = (props) => {
  const { company } = useSelectedCompanyQuery();

  const user = useStore($User) as UserDataT;
  const allTariffs = useStore($AllTariffs);

  const queryClient = useQueryClient();

  const permissions = useStore($UserAddPermissions);
  const [accountPanelDisplay, setAccountPanelDisplay] =
    useState<boolean>(false);
  const { open } = useModal();
  const history = useHistory();

  useEffect(() => {
    fetchAllTariffs();
  }, []);

  const menuPanelRef = useRef<HTMLDivElement>(null);

  const handlerTitleOptions = (str?: string) => {
    if (str?.includes("[")) {
      // eslint-disable-next-line no-useless-escape
      return str.replace(/[\[\(][^\]\)]*[\]\)]/g, "");
    }
    return str;
  };

  useOnClickOutside(menuPanelRef, () => {
    setAccountPanelDisplay(false);
  });

  const handleLogout = () => {
    AuthService.logout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("selectedCompany");
      queryClient.clear();
      history.push("/login");
    });
  };

  return (
    <header className={clsx(style.header)}>
      <div className={clsx(style.header_cont)}>
        <MenuBurgerIcons {...props} />
        <div className={clsx(style.right_side)}>
          <div className={clsx(style.logo)}>
            <Link to={"/"}>
              <img
                src={logo}
                alt="OT"
                className={clsx(style.headerLogoImg)}
              />
            </Link>
            {/* {permissions.hasRequiredLevel(UserPriorityEnum.Admin) ? (
              <Link to={"/"}>
                <img
                  src={logo}
                  alt="OT"
                  className={clsx(style.headerLogoImg)}
                />
              </Link>
            ) : (
              <div className={clsx(style.logo_user)}>
                <Link to={"/company"}>
                  {company?.image &&
                  !company.image.includes("/img/static/dummy-logo.png") ? (
                    <img
                      src={getCompanyLogo(company.image)}
                      alt=""
                      className={style.headerLogoImg}
                    />
                  ) : (
                    <BodyNormal sx={{ fontSize: 14 }} weight="bold">
                      {company?.name}
                    </BodyNormal>
                  )}
                </Link>
              </div>
            )} */}
          </div>
          <div className={clsx(style.header_tariff)}>
            {/* <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}> */}
            {!permissions.hasRequiredLevel(UserPriorityEnum.Admin) &&
            company &&
            [1, 2, 3, 4, 5].includes(company.tariff) ? (
              <>
                <div>
                  {[TariffEnum.Temp, TariffEnum.Demo].includes(
                    company.tariff
                  ) ? (
                    <p className={clsx(style.tarrif_name)}>
                      {company.tariff === 4 ? "Временный" : "Демо дни"}
                    </p>
                  ) : (
                    <>
                      <p className={clsx(style.tarrif_title)}>
                        Тариф:{" "}
                        <span className={clsx(style.tarrif_name)}>
                          {allTariffs &&
                            handlerTitleOptions(
                              allTariffs[company.tariff - 1].title
                            )}
                        </span>
                      </p>
                      <p className={clsx(style.tarrif_title)}>
                        Истекает:{" "}
                        <span className={clsx(style.tarrif_name)}>
                          {moment(company.tariffExpires).format("DD.MM.YYYY")}
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <div className="remains-free-space">
                  {[TariffEnum.Temp, TariffEnum.Demo].includes(
                    company.tariff
                  ) ? (
                    <>
                      <progress
                        value={company.tariffExpires - Date.now()}
                        max={company.tariffExpires - company.tariffStarts}
                      ></progress>
                      <p>
                        {Math.ceil(
                          (company.tariffExpires - Date.now()) / 86400000
                        ) === 1
                          ? "Остался"
                          : "Осталось"}{" "}
                        {Math.ceil(
                          (company.tariffExpires - Date.now()) / 86400000
                        )}{" "}
                        {renderDayWithEnding(
                          Math.ceil(
                            (company.tariffExpires - Date.now()) / 86400000
                          )
                        )}
                      </p>
                    </>
                  ) : (
                    <CompanyDiskUsage company={company} />
                  )}
                </div>
              </>
            ) : null}
            <CompanySearch />
            <div
              className={clsx(style.header_account_panel)}
              ref={menuPanelRef}
            >
              <Link to={"/profile"} className={clsx(style.user_small_img_link)}>
                <img
                  src={
                    user.avatar
                      ? concatApiUrl(`/uploads/users/avatars/${user.avatar}`)
                      : concatClientUrl("/img/static/dummy-avatar.png")
                  }
                  alt=""
                  className={clsx(style.user_small_img)}
                />
              </Link>
              {!user ? (
                <Loader />
              ) : (
                <>
                  <div
                    className={clsx(style.header_panel_wrapper)}
                    onClick={() => {
                      setAccountPanelDisplay(!accountPanelDisplay);
                    }}
                  >
                    <div className={clsx(style.header_user_data)}>
                      <p className={clsx(style.user_name)}>{user.name}</p>
                    </div>
                    <img
                      src="/img/static/black-arrow-drop.png"
                      alt=""
                      className={clsx(style.dropdown_arrow)}
                    />
                  </div>
                </>
              )}
              {accountPanelDisplay && (
                <div className={clsx(style.account_dropdown)}>
                  <div className={clsx(style.account_dropdown_cont)}>
                    <div className={clsx(style.user_panel_actions)}>
                      {permissions.hasPermission(
                        ACTIONS.companies_allowedToCreate
                      ) && (
                        <button
                          className={clsx(style.user_panel_action_item)}
                          onClick={() => open("CreateCompanyModal")}
                        >
                          <img src="/img/static/green-plus.png" alt="" />
                          Создать компанию
                        </button>
                      )}
                      <Link
                        to={"/profile"}
                        className={clsx(style.user_panel_action_item)}
                      >
                        <img src="/img/static/green-user.png" alt="" />
                        Личный кабинет
                      </Link>
                      <Link
                        to={"/user-guide"}
                        className={clsx(style.user_panel_action_item)}
                      >
                        <img src="/img/static/qa-icon.png" alt="" />
                        Руководство пользователя
                      </Link>
                      <button className={clsx(style.user_panel_action_item)}>
                        <img src="/img/static/green-pen.png" alt="" />
                        Получить сертификат пользователя
                      </button>
                      <button
                        className={clsx(style.user_panel_action_item)}
                        onClick={handleLogout}
                      >
                        <img src="/img/static/log-out.png" alt="" />
                        Выйти
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
