import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
  UserRoleEnum,
} from "@store/user-store";
import { $Workers, $WorkersDataStates } from "@store/workers-store";

import { ErrorIndicator } from "@ui/indicators";
import { Link } from "react-router-dom";
import Loader from "@ui/indicators/loader";
import { NotePencil } from "@phosphor-icons/react";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/components/tables/users-table.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";

const UsersTable: React.FC = () => {
  const workers = useStore($Workers);
  const { isLoading, error } = useStore($WorkersDataStates);
  const permissions = useStore($UserAddPermissions);

  const { open } = useModal();

  const tableBodyContent = workers.map((worker) => (
    <tr key={worker.id}>
      <td>
        <Link to={`/users/${worker.id}`}>
          <label
            htmlFor={`key`}
            className={clsx(tableStyle.column_fixed_height)}
          >
            <input
              type="checkbox"
              className={clsx(tableStyle.checkbox_item)}
              hidden
              disabled
              name=""
              id={`key`}
            />
            <label htmlFor={`key`}>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </label>
            <label htmlFor={`key`} className={clsx(tableStyle.checkbox_label)}>
              {worker.name}
            </label>
          </label>
        </Link>
      </td>
      <td>
        <p className={clsx(style.user_email)}>{worker.email}</p>
        {((worker.roleId === UserRoleEnum.Worker &&
          permissions.hasPermission(
            ACTIONS.roles_levelFourthRoles_allowedToEdit
          )) ||
          (worker.roleId === UserRoleEnum.Client &&
            permissions.hasPermission(
              ACTIONS.roles_levelThirdRoles_allowedToEdit
            )) ||
          (worker.roleId === UserRoleEnum.Admin &&
            permissions.hasPermission(
              ACTIONS.roles_levelSecondRoles_allowedToEdit
            )) ||
          (worker.roleId === UserRoleEnum.SuperAdminMinus &&
            permissions.hasPermission(
              ACTIONS.roles_levelFirstRoles_allowedToEdit
            )) ||
          permissions.hasRequiredLevel(UserPriorityEnum.Owner)) && (
          <Tooltip title="Изменить" placement={"top"}>
            <button
              onClick={() =>
                open("CreateUserModal", {
                  modalData: {
                    modalTitle: "Изменить пользователя",
                    worker,
                  },
                  btnText: "Сохранить",
                })
              }
            >
              <WrapperSVG color={"orange"}>
                <NotePencil size={24} />
              </WrapperSVG>
            </button>
          </Tooltip>
        )}
      </td>
    </tr>
  ));

  return (
    <div className={clsx(tableStyle.base_table_container)}>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorIndicator />
      ) : (
        <table className={clsx(tableStyle.base_table)}>
          <thead>
            <tr>
              <td>
                <label>
                  <input
                    type="checkbox"
                    className={clsx(tableStyle.checkbox_item)}
                    hidden
                    name=""
                    disabled
                  />
                  <label>
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                  <label className={clsx(tableStyle.checkbox_label)}>Имя</label>
                </label>
              </td>
              <td>Почта</td>
            </tr>
          </thead>
          <tbody>{tableBodyContent}</tbody>
        </table>
      )}
    </div>
  );
};

export default UsersTable;
